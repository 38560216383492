import getData from "@/__main__/get-data.mjs";
import MetaMapsModel from "@/data-models/valorant-meta-maps.mjs";
import { getValorantConstDataByType } from "@/game-val/api.mjs";

const networkOptions = {
  networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
};

export default function fetchData() {
  return getData(
    getValorantConstDataByType("maps"),
    MetaMapsModel,
    ["val", "meta", "maps"],
    networkOptions,
  );
}
